import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import Text from "../../atoms/Text/Text";

export default function UserTermsAndConditionsAgreement({ className }: { className?: string }) {
  const { t } = useTranslation();

  return (
    <Text variant="finePrint" className={classNames("text-grey-60 italic my-2.5", className)}>
      {t("order:user_purchase_agreement_part_1")}{" "}
      <Link className="cursor-pointer underline" href="/termsandconditions" target="_blank">
        {t("common:terms_and_conditions")}
      </Link>{" "}
      {t("order:user_purchase_agreement_part_2")}{" "}
      <Link className="cursor-pointer underline" href="/privacy" target="_blank">
        {t("common:privacy_policy")}
      </Link>
    </Text>
  );
}
